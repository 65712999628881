@font-face {
  font-family: "SuisseIntl";
  src: url("/public/fonts/suisse-intl-700.woff2") format("woff2"),
    url("/public/fonts/suisse-intl-700.ttf") format("truetype");

  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("/public/fonts/suisse-intl-400.otf") format("opentype"),
    url("/public/fonts/suisse-intl-400.ttf") format("truetype");

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "SuisseIntl", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #eee8e7;
  padding: 0px calc(50% - 520px);
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: "SuisseIntl", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

img {
  width: 100%;
  max-width: 396px;
  max-height: 396px;
}

/* Large screen */
@media screen and (min-width: 1440px) {
  body {
    padding: 0px calc(50% - 520px);
  }
}

/* desktop screen */
@media screen and (max-width: 1439px) and (min-width: 1240px) {
  body {
    padding: 0px 200px;
  }
}

/* laptop screen */
@media screen and (max-width: 1239px) and (min-width: 905px) {
  body {
    padding: 0px calc(50% - 420px);
  }
}

/* tablet screen */
@media screen and (max-width: 904px) and (min-width: 600px) {
  body {
    padding: 0px 32px;
  }
}

/* mobile screen */
@media screen and (max-width: 600px) {
  body {
    padding: 48px 15px;
  }
  img {
    border-radius: 1.5vw;
    object-fit: scale-down;
    width: 100%;
  }
}
