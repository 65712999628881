.App {
  display: flex;
  gap: 48px;
  justify-content: center;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.block__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  max-width: 396px;
  width: 100%;
}

.block__content-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
}

.block__content-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.01em;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 904px) and (min-width: 600px) {
  .App {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 302px;
    flex-wrap: wrap;
    align-items: center;
  }

  .block__content {
    max-width: 259px;
    min-width: 252px;
  }
}

@media screen and (max-width: 599px) {
  .App {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }

  .block__content {
    max-width: 363px;
  }

  img {
    width: 240px;
    height: 240px;
  }
}
